// Version v1.3.0-09 2025-03-30
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleClearData = () => {
    localStorage.clear();
    alert('A böngésző tárolt adatai törölve lettek.');
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setLoading(false);
        },
        (error) => {
          console.error("Hiba a GPS-adatok lekérésében:", error);
          setError("Nem sikerült lekérni a helyadatokat.");
          setLoading(false);
        }
      );
    } else {
      console.error("A geolocation nem támogatott ebben a böngészőben.");
      setError("A geolocation nem támogatott ebben a böngészőben.");
      setLoading(false);
    }
  }, []);

  return (
    <div className="homeContainer">
      <div className="headerRow">
        <button className="clearButton" onClick={handleClearData}>Törlés</button>
        <h1 className="centeredTitle">Veritas v1.3.0-09</h1>
      </div>
      <div className="buttonGrid">
        <Link to="/switches" className="button">Hunyadi utca</Link>
        <Link to="/hegyalja" className="button">Hegyalja utca</Link>
      </div>
      <div className="coordinates">
        {loading ? (
          <p>Helyadatok lekérése...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <p>Szélesség: {coordinates.latitude}</p>
            <p>Hosszúság: {coordinates.longitude}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;
