// Version 1.2.0-06 2025-03-30
import React, { useState, useEffect } from 'react';
import SwitchComponent from './SwitchComponent';
import { fetchDataFromServer } from '../apiUtils';
import { useNavigate } from 'react-router-dom'; // React Router navigáció

const SwitchesPage = () => {
  const navigate = useNavigate(); // Navigációs hook
  const [switches, setSwitches] = useState([
    { label: 'LED 1', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'LED 2', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Kapcsoló 3', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Kapcsoló 4', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Kapcsoló 5', value: false, onColor: '#FF4500', offColor: '#39FF14' },
    { label: 'Kapcsoló 6', value: false, onColor: '#FF4500', offColor: '#39FF14' },
  ]);
  const [heartbeat, setHeartbeat] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState('');
  const [serverValue, setServerValue] = useState(26);
  const [responseText, setResponseText] = useState('');

  const [bmpTemp, setBmpTemp] = useState(null);
  const [bmpPress, setBmpPress] = useState(null);
  const [bmpHumi, setBmpHumi] = useState(null);

  const toggleSwitch = (index) => {
    const updatedSwitches = switches.map((sw, i) => (i === index ? { ...sw, value: !sw.value } : sw));
    setSwitches(updatedSwitches);
    sendDataToServer(`SZ_lamp_${index + 1}`, updatedSwitches[index].value ? 'true' : 'false');
  };

  const changeValue = (amount) => {
    const newValue = Math.max(0, serverValue + amount);
    setServerValue(newValue);
    sendDataToServer('security_code', newValue);
  };

  const sendDataToServer = (variable, value) => {
    fetchDataFromServer('API-001', variable, 'POST', value)
      .then((response) => {
        if (response) {
          setResponseText(`Válasz: ${JSON.stringify(response)}`);
        }
      })
      .catch((error) => {
        setResponseText(`Hiba: ${error.message}`);
      });
  };

  const fetchInitialState = () => {
    fetchDataFromServer('API-001', '', 'POST')
      .then((response) => {
        if (response) {
          setSwitches((prev) =>
            prev.map((sw, i) => ({
              ...sw,
              value: response[`SZ_lamp_${i + 1}`]?.value === 'true',
            }))
          );
          setServerValue(parseInt(response.security_code?.value, 10) || 0);
          setHeartbeat(response.HeartBeat?.value === 'true');

          setBmpTemp(parseFloat(response.Temp_BMP?.value) || null);
          setBmpPress(parseFloat(response.Press_BMP?.value) || null);
          setBmpHumi(parseFloat(response.Humi_BMP?.value) || null);
        }
      })
      .catch((error) => {
        console.error('Hiba a jelenlegi állapotok lekérésekor:', error);
      });
  };

  const fetchHeartbeat = () => {
    const url = `https://hacsi.hu/saveData.php?apikey=API-001&var=HeartBeat`;

    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Kapott válasz a HeartBeat-től:', data);

        if (data && data.HeartBeat && data.HeartBeat.value !== undefined) {
          setHeartbeat(data.HeartBeat.value === 'true');
        } else {
          console.warn('Nincs megfelelő HeartBeat adat a válaszban:', data);
        }
        setLastRequestTime(new Date().toLocaleString());
      })
      .catch((error) => {
        console.error('Hiba a HeartBeat lekérésekor:', error);
      });
  };

  useEffect(() => {
    fetchInitialState();
    const heartbeatInterval = setInterval(fetchHeartbeat, 2000);
    const sensorInterval = setInterval(fetchInitialState, 20000); // ⬅️ BMP értékek frissítése 10 mp-enként
    return () => {
      clearInterval(heartbeatInterval);
      clearInterval(sensorInterval);
    };
  }, []);
  

  return (
    <div className="pageContainer">
      <div className="headerRow">
        <button className="backButton" onClick={() => navigate('/')}>Vissza</button>
        <h2 className="text">Hunyadi utca</h2>
        <div className={`lamp ${heartbeat ? 'active' : ''}`}></div>
      </div>
      <div className="row">
        {switches.slice(0, 2).map((sw, i) => (
          <SwitchComponent key={i} {...sw} onToggle={() => toggleSwitch(i)} />
        ))}
      </div>
      <div className="row">
        {switches.slice(2, 4).map((sw, i) => (
          <SwitchComponent key={i + 2} {...sw} onToggle={() => toggleSwitch(i + 2)} />
        ))}
      </div>


  <div className="buttonGrid">
   <div className="buttonRow">
     <button className="button" onClick={() => sendDataToServer('HU_kert_1', 10)}>HU kert 1</button>
     <button className="button" onClick={() => sendDataToServer('HU_kert_2', 20)}>HU kert 2</button>
   </div>
  </div>

      <div className="temperatureRow">
  <div className="temperatureBox">
    <h3>Temp_BMP</h3>
    <p>{bmpTemp !== null ? `${bmpTemp.toFixed(1)} °C` : 'N/A'}</p>
  </div>
  <div className="temperatureBox">
    <h3>Press_BMP</h3>
    <p>{bmpPress !== null ? `${bmpPress.toFixed(0)} hPa` : 'N/A'}</p>
  </div>
</div>
<div className="temperatureRow">
  <div className="temperatureBox">
    <h3>Humi_BMP</h3>
    <p>{bmpHumi !== null ? `${bmpHumi.toFixed(1)} %` : 'N/A'}</p>
  </div>
</div>

<div className="securityCodeRow">
        <h2>Biztonsági kód</h2>
        <div style={{ margin: '0 10px' }}>
          <button onClick={() => changeValue(-1)} className="counterButtonSmall">-</button>
        </div>
        <p className="counterTextSmall">{serverValue}</p>
        <div style={{ margin: '0 10px' }}>
          <button onClick={() => changeValue(1)} className="counterButtonSmall">+</button>
        </div>
      </div>

      <div className="responseContainer">
        <p className="responseText">{responseText}</p>
        <p className="responseText">Lekér: {lastRequestTime}</p>
      </div>

    </div>
  );
};

export default SwitchesPage;
